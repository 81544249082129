<template>
	<WwModal
		:modal-id="NEWSLETTER_SIGNUP_MODAL"
		modal-border-radius="rounded-t-2xl md:rounded-2xl"
		modal-margin="m-0"
		modal-padding="p-0"
		modal-position="items-end md:items-center"
		max-width="w-full md:max-w-sm"
		hide-footer
		:transition-direction="isMobile ? 'bottom' : 'down'"
	>
		<template #title>
			<div class="absolute w-full transform -translate-x-1/2 -translate-y-1/2 top-10 left-1/2">
				<WwLogo
					full
					:width="250"
					class="mx-auto"
				/>
			</div>
		</template>
		<template #body>
			<NewsletterForm class="p-6" />
		</template>
	</WwModal>
</template>

<script async>
import { mapGetters } from 'vuex'

import WwLogo from '@/components/multiUse/WwLogo.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { NEWSLETTER_SIGNUP_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		WwModal,
		WwLogo,
		NewsletterForm: () => import('@/components/multiUse/NewsletterForm.vue')
	},
	data() {
		return {
			NEWSLETTER_SIGNUP_MODAL,
			isExpanded: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ])
	}
}
</script>
